import React from 'react';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { StyledButton } from 'components/forms/Buttons';
import { ReactComponent as Success } from 'assets/icons/success.svg';
import { ReactComponent as Failed } from 'assets/icons/failed.svg';
import { Wrapper } from './orderPlaced.styled';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCart } from 'helpers/contexts/cart-context';
import { useBuyerAuth } from 'helpers/contexts/buyer-auth-context';
import { checkPaymentStatusApi } from 'helpers/http/cartApi';


const StyledButtonWrapper = styled(StyledButton)`
  margin: 0rem 0.375rem 1rem 0.375rem;
`;

function OrderPlaced() {
  const { user, signout } = useBuyerAuth();
  const { removeAll } = useCart();
  const location = useLocation();
  const navigate = useNavigate();
  const [orderState, setOrderState] = React.useState<any>(null);
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const role = params.get('role');
    const orderId = params.get('order_id');
    const status = params.get('status');
    const error = params.get('error');
    setOrderState({ role, orderId, status, error });
  }, [location.search]);

  React.useEffect(() => {
    removeAll();
  }, []);

  React.useEffect(() => {
    if (user.isGuest) {
      localStorage.removeItem('buyer-user');
      localStorage.removeItem('buyer-token');
    }
  }, [signout, user.isGuest]);

  if (orderState?.role?.includes('guest')) {
    return (
      <>
        <Wrapper>
          <SiteHeader />
          <div className="d-flex justify-content-center align-items-center main-container">
            {orderState?.status === 'success' ? <Success /> : <Failed />}
            <h4 className="title mt-1.5 mb-0 fw-700 fs-32">
              {orderState?.status === 'success'
                ? 'Order placed!'
                : 'Order failed!'}
            </h4>
            {orderState?.status === 'success' && (
              <>
                <p className="content">
                  Your order placed successfully. We’ve sent you a email with
                  all the details.
                </p>
                <h5>Order id : {orderState?.orderId}</h5>
              </>
            )}
            {orderState?.status === 'failed' &&
              orderState?.error === 'out-of-stock' && (
                <>
                  <p className="content">
                    Looks like someone beat you to this item!
                    <br />
                    Please go back to the item and see if it’s still in stock!
                  </p>
                </>
              )}
            <div className="d-flex flex-wrap justify-content-center buttons">
              <StyledButtonWrapper
                variant="secondary"
                onClick={() => {
                  setTimeout(() => {
                    window.location.replace('/home');
                  }, 1000);
                }}
              >
                Continue buying
              </StyledButtonWrapper>
            </div>
          </div>
        </Wrapper>
        <SiteFooter />
      </>
    );
  }

  return (
    <>
      <Wrapper>
        <SiteHeader />
        <div className="d-flex justify-content-center align-items-center main-container">
          {orderState?.status === 'success' ? <Success /> : <Failed />}
          <h4 className="title mt-1.5 mb-0 fw-700 fs-32">
            {orderState?.status === 'success'
              ? 'Order placed!'
              : 'Order failed!'}
          </h4>
          {orderState?.status === 'success' && (
            <p className="content">
              Your order placed successfully. For more details check My orders
              under ‘Account’
            </p>
          )}
          {orderState?.status === 'failed' &&
            orderState?.error === 'out-of-stock' && (
              <>
                <p className="content">
                  Looks like someone beat you to this item!
                  <br />
                  Please go back to the item and see if it’s still in stock!
                </p>
              </>
            )}
          <div className="d-flex flex-wrap justify-content-center buttons">
            <Link to="/my-account">
              <StyledButtonWrapper variant="dark" type="submit">
                Go to My Account
              </StyledButtonWrapper>
            </Link>
            <Link to="/home">
              <StyledButtonWrapper variant="secondary" type="submit">
                Continue buying
              </StyledButtonWrapper>
            </Link>
          </div>
        </div>
      </Wrapper>
      <SiteFooter />
    </>
  );
}

export default OrderPlaced;
