import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import PaypalExpressBtn from 'react-paypal-express-checkout';

import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { processPayment } from 'helpers/http/cartApi';
import { useCart } from 'helpers/contexts/cart-context';
import { showErr, showMsg } from 'helpers/utils/misc';
import { useBuyerAuth } from 'helpers/contexts/buyer-auth-context';

function PaypalPay({
  amount,
  orderDetails,
}: {
  amount: string;
  orderDetails: any;
}) {
  const { user } = useBuyerAuth();
  const [isRenderd, setIsRendered] = React.useState(false);
  React.useEffect(() => {
    setIsRendered(false);
    setTimeout(() => {
      setIsRendered(true);
    }, 200);
  }, [amount]);
  const navigate = useNavigate();
  // const { removeAll } = useCart();
  const handlePayment = (id: string) => {
    const promise = processPayment({
      paypalRefId: id,
      ...orderDetails,
    });
    toast.promise(promise, {
      loading: 'Processing...',
      success: (res) => {
        const status = res.status ? 'success' : 'failed';
        if (user.isGuest === true) {
          navigate(
            `/order-placed?order_id=${res.order_no}&role=guest&status=${status}`
          );
        }
        navigate(`/order-placed?order_id=${res.order_no}&status=${status}`);
        return res.message;
      },
      error: (error) => {
        let errorCode;
        if (
          error?.toString() &&
          error?.toString().includes('Product is out of stock')
        ) {
          errorCode = 'out-of-stock';
        }
        navigate(
          `/order-placed?status=failed${errorCode ? `&error=${errorCode}` : ''}`
        );
        return (
          error.response?.data?.message ||
          error.toString() ||
          'Payment failed ! Please try again.'
        );
      },
    });
  };
  if (!isRenderd) return null;
  return (
    <>
      <PayPalScriptProvider
        options={{ 'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID || '' }}
      >
        <div className="text-center">
          <span style={{ fontSize: 12 }}>Pay with</span>
          <PayPalButtons
            // style={{ layout: 'horizontal', height: 50 }}
            createOrder={(data, actions: any) => {
              // console.log('onCreateOrder', data, actions);
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: amount,
                    },
                  },
                ],
                application_context: {
                  shipping_preference: 'NO_SHIPPING',
                  // buyer-: 'en-US',
                },
              });
            }}
            onApprove={(data, actions: any) => {
              return actions.order.capture().then((details: any) => {
                if (details.status !== 'COMPLETED') {
                  showErr('Payment failed ! Please try again.');
                  return;
                }
                handlePayment(data.orderID);
              });
            }}
          />
        </div>
      </PayPalScriptProvider>

      {/* <PaypalExpressBtn
        env="sandbox"
        client={{
          sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        }}
        currency={'USD'}
        total={amount}
        onError={(e: any) => console.log('err', e)}
        onSuccess={(e: any) => console.log('success', e)}
        onCancel={(e: any) => console.log('cancel', e)}
      />
      <div>hhhhhh</div>
      <form
        action="https://securepayments.paypal.com/webapps/HostedSoleSolutionApp/
webflow/sparta/hostedSoleSolutionProcess"
        method="post"
      >
        <input type="hidden" name="cmd" value="_hosted-payment" />
        <input type="hidden" name="subtotal" value="50" />
        <input type="hidden" name="business" value="HNZ3QZMCPBAAA" />
        <input type="hidden" name="paymentaction" value="sale" />
        <input
          type="hidden"
          name="return"
          value="https://yourwebsite.com/receipt_page.html"
        />
        <input type="submit" name="METHOD" value="Pay" />
      </form> */}
    </>
  );
}

export default PaypalPay;
