import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Form,
  Spinner,
  Image,
  Button,
} from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import SiteHeader from 'components/header/Header';
import SiteFooter from 'components/footer/Footer';
import { StyledButton, TransparentButton } from 'components/forms/Buttons';
import ErrorMessage from 'components/ui/ErrorMessage';
import BreadCrumbView from 'components/breadcrumb-view/BreadCrumbView';
import { BreadCrumbItem } from 'components/breadcrumb-view/breadcrumb.type';
import AddressSummary from './AddressSummary';
import OrderSummary from './OrderSummary';
import { ReactComponent as BulletIcon1 } from 'assets/icons/bulletIcon1.svg';
import { ReactComponent as BulletIcon2 } from 'assets/icons/bulletIcon2.svg';
import { ReactComponent as BulletIcon3 } from 'assets/icons/bulletIcon3.svg';
import { ReactComponent as SelectLine } from 'assets/icons/selectLine.svg';
import { ReactComponent as USPSIcon } from 'assets/icons/usps.svg';
import { ReactComponent as Line } from 'assets/icons/Line.svg';

import {
  Wrapper,
  Content,
  PaymentWrapper,
  OrderDetailsWrapper,
} from './buyerCheckout.styled';
import { useCart } from 'helpers/contexts/cart-context';
import {
  checkoutApi,
  createCheckoutSession,
  getCartPrice,
} from 'helpers/http/cartApi';
import {
  createAddress,
  editAddress,
  getAddress,
  getCards,
} from 'helpers/http/account';
import toast from 'react-hot-toast';
import {
  placesToAddress,
  showErr,
  showMsg,
  transformAddress,
} from 'helpers/utils/misc';
import AddressCard from './AddressCard';
import SavedCards from './SavedCards';
import AutoComplete from 'pages/buyer-checkout/AddressAutoComplete';
import PaypalPay from './PaypalPay';
import useCityCountryState from 'helpers/hooks/useCityCountryState';
import Loader from 'components/Loader';
import { useAuth } from 'helpers/contexts/auth-context';
import { useBuyerAuth } from 'helpers/contexts/buyer-auth-context';
import { useQuery } from 'react-query';
import { getCart, getShippingMethods } from 'helpers/http/cartApi';
import Checkbox from 'components/forms/Checkbox';
import ShippingMethodSummary from './ShippingMethodSummary';
import PaymentDetails from './paymentDetails';

type CartPrice = {
  otherCost: string;
  subTotal: string;
  taxAmount: string;
  taxRate: string;
  totalAmount: string;
  clientSecret: string;
  paymentIntentId: string;
};

type CardType = {
  user_card_id: string;
  card_type: string;
  exp_date: string;
  last_4_digit: number;
};

const defaultState = {
  full_name: '',
  phone_number: '',
  address_1: '',
  address_2: '',
  postcode: '',
  city: '',
  state: '',
  country: '',
};

const phoneRegExp =
  /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const schema = (email: boolean) =>
  yup
    .object({
      full_name: yup.string().required('Full name is required'),
      phone_number: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('Please enter a valid 10 digit phone number.'),
      address_1: yup.string().required('Address line 1 is required.'),
      address_2: yup.string(),
      postcode: yup.string().required('Postal code is required'),
      city: yup
        .string()
        .matches(/^[A-Za-z ]*$/, 'Please enter a valid city.')
        .required('Please enter a city.'),
      state: yup.string().required('State is required'),
      email: email
        ? yup
          .string()
          .email('Please enter a valid email address.')
          .required('Email is required')
        : yup.string(),
    })
    .required();

const shippingMethodIconMapper: { [key: string]: any } = {
  USPS_INTR: USPSIcon,
};

function ShippingMethodCard({
  shippingMethod,
  active,
  onSelect,
}: {
  shippingMethod: any;
  active: boolean;
  onSelect: (shippingMethod: any) => void;
}) {
  const Icon = shippingMethodIconMapper[shippingMethod.shipping_key] || null;
  return (
    <div
      className={`d-flex justify-content-between align-items-center shipping-method-card ${active ? 'active' : ''
        }`}
    >
      <div className="d-flex align-items-center">
        <Checkbox checked={active} toggle={() => onSelect(shippingMethod)} />
        <div onClick={() => onSelect(shippingMethod)}>
          {Icon ? (
            <div>
              <Icon />
            </div>
          ) : (
            <span className="d-flex flex-column">
              {shippingMethod.shipping_name}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function BuyerCheckout() {
  const { user } = useBuyerAuth();
  const navigate = useNavigate();
  const { cart, removeAll, setCart } = useCart();
  const [currentStep, setCurrentStep] = useState(1);
  const [addressList, setAddressList] = useState<any>([]);
  const [cartPrice, setCartPrice] = useState<CartPrice>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shippingMethodList, setShippingMethodList] = useState<any[]>([]);
  const [shippingMethod, setShippingMethod] = useState<any | null>(null);
  const [appliedCouponCode, setAppliedCouponCode] = useState<string>('');
  const [couponCode, setCouponCode] = useState<string>('');
  const [couponError, setCouponError] = useState<string>('');
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [orderPlaced, setOrderPlaced] = useState<boolean>(false);
  const [userAddressId, setUserAddressId] = useState<any>(null);
  // const [cardList, setCardList] = React.useState<CardType[]>([]);
  // const [selectedCard, setSelectedCard] = useState<any>(null);
  const [form, setForm] = useState(defaultState);
  const [savingAddress, setSavingAddress] = useState<boolean>(false);
  const [savingShippingMethod, setSavingShippingMethod] =
    useState<boolean>(false);
  // const [processingPayment, setProcessingPayment] = useState<boolean>(false);
  const [paymentIntentClientSecret, setPaymentIntentClientSecret] =
    useState<any>(null);

  const globalData = useCityCountryState();
  const [priceLoader, setPriceLoader] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [addressState, setAddressState] = useState<any>();
  const breadcrumbList: BreadCrumbItem[] = [
    { title: 'Home' },
    { title: 'Cart' },
    { title: 'Checkout' },
  ];

  const ids = cart.map((c: any) => c.user_product_id);
  const {
    data: cartData,
    isLoading: cartLoading,
    refetch,
  } = useQuery(['checkout-cart', ids], () => getCart(ids), {
    enabled: ids.length > 0,
  });

  React.useEffect(() => {
    if (cartData) {
      if (cartData?.data) {
        const removedLength = ids.length - cartData?.data?.length;
        if (removedLength > 0) {
          showMsg(
            `${removedLength} product(s) were taken out of the cart because they are out of stock`
          );
        }
        setCart([...cartData.data]);
      } else {
        setCart([]);
      }
    }
  }, [cartData]);

  const { register, handleSubmit, formState, setValue, reset } = useForm({
    resolver: yupResolver(schema(Boolean(user.isGuest))),
  });

  React.useEffect(() => {
    if (!cart?.length && !orderPlaced) {
      showErr('Cart is empty!');
      navigate('/home');
    }
  }, [cart?.length, navigate]);

  const getSavedAddresses = React.useCallback(() => {
    getAddress().then((res) => {
      setAddressList(res.data);
      if (res.data.length > 0) {
        const defaultAdd = res.data.find((item: any) => item.is_default);
        if (defaultAdd) {
          setUserAddressId(defaultAdd.user_address_id);
        } else {
          setUserAddressId(undefined);
        }
      } else {
        setUserAddressId(undefined);
        showAddAddressForm();
      }
    });
  }, []);

  React.useEffect(() => {
    if (user.isGuest === true) {
      showAddAddressForm();
      return;
    } else {
      getSavedAddresses();
    }
    // getSavedCards();
  }, [getSavedAddresses, user]);

  // const getSavedCards = () => {
  //   // getCards().then((res: any) => {
  //   //   // setCardList([...res.data]);
  //   //   // if (res.data.length > 0) {
  //   //   //   setSelectedCard(res.data[0]);
  //   //   // }
  //   // });
  // };

  const cartItemArray = React.useMemo<string[] | null>(
    () => cart.map((item: any) => item.user_product_id),
    [cart]
  );

  React.useEffect(() => {
    if (user.isGuest !== true && userAddressId === null) {
      return;
    }
    if (cartLoading) return;
    if (cartItemArray && cartItemArray.length > 0) {
      setPriceLoader(true);
      getCartPrice(
        cartItemArray,
        userAddressId,
        appliedCouponCode,
        shippingMethod?.shipping_key
      ).then((res) => {
        if (res.status && res.data) {
          setCartPrice(res.data);
          setPriceLoader(false);
        } else {
          refetch();
        }
      });
    }
  }, [cartItemArray, userAddressId, user, cartLoading]);

  const fetchPaymentIntent = async () => {
    if (user.isGuest !== true && userAddressId === null) {
      return;
    }
    if (cartLoading) return;
    if (cartItemArray && cartItemArray.length > 0) {
      setPaymentLoader(true);
      createCheckoutSession(
        cartItemArray,
        userAddressId,
        appliedCouponCode,
        shippingMethod?.shipping_key
      ).then((res) => {
        if (res.status && res.data) {
          const { clientSecret } = res.data;
          setPaymentIntentClientSecret(clientSecret);
          setPaymentLoader(false);
        }
      });
    }
  };

  React.useEffect(() => {
    if (currentStep === 3) {
      fetchPaymentIntent();
    }
  }, [currentStep]);

  useEffect(() => {
    getShippingMethods().then((res) => {
      if (res.status && res.data) {
        setShippingMethodList(res.data);
      }
    });
  }, []);

  const onContinue = (data: any) => {
    setForm(data);
    saveAddress(data);
  };
  const resetFields = () => {
    reset();
    globalData.setSelectedCountry('');
    globalData.setSelectedState('');
    setAddressState('');
  };
  const saveAddress = (data: any) => {
    data.phone_number = data.phone_number + '';
    const payload = {
      full_name: data.full_name,
      phone_number: data.phone_number,
      address_1: data.address_1,
      address_2: data.address_2,
      postcode: data.postcode,
      city: data.city,
      state: globalData.selectedState,
      country: globalData.selectedCountry,
      email_id: data.email,
    };
    setSavingAddress(true);
    const promise =
      userAddressId == null || user.isGuest === true
        ? createAddress(payload)
        : editAddress({ ...payload, user_address_id: userAddressId });

    promise.then((res) => {
      if (!res.status) {
        toast.error(res.message);
        return;
      }
      const newUserAddressId = res.user_address_id || userAddressId;
      if (newUserAddressId) {
        setUserAddressId(newUserAddressId);
        if (cartItemArray && cartItemArray.length > 0) {
          setPriceLoader(true);
          getCartPrice(
            cartItemArray,
            newUserAddressId,
            appliedCouponCode,
            shippingMethod?.shipping_key
          ).then((res) => {
            if (res.status && res.data) {
              setCartPrice(res.data);
              setPriceLoader(false);
            } else {
              refetch();
            }
          });
        }
        // clear State
        resetFields();
      }
      setCurrentStep(2);
      toast.success(
        userAddressId == null
          ? 'Address created successfully'
          : 'Address updated successfully'
      );
      setSavingAddress(false);
    });
  };

  const onPay = (token: any) => {
    const promise = checkoutApi({
      tokenId: token,
      user_product_ids: cartItemArray,
      user_address_id: userAddressId,
    });
    toast.promise(promise, {
      loading: 'Processing...',
      success: (res) => {
        setOrderPlaced(true);
        removeAll();
        navigate(`/order-placed`);
        return res.message || 'Your order was placed successfully!';
      },
      error: (err) => {
        return err?.response?.data?.message || err.toString();
      },
    });
  };

  const onEditAddress = () => {
    if (user.isGuest === true) {
      const formData: any = form;
      for (const formkey in formData) {
        setValue(formkey, formData[formkey]);
      }
      setAddressState(formData.address_1);
      globalData.setSelectedCountry(formData.country);
      globalData.setSelectedState(formData.state);
    }
    setCurrentStep(1);
  };

  const handleAddressContinue = () => {
    if (userAddressId) {
      setCurrentStep(2);
    } else {
      showErr('Please add address first');
      setShowAddressForm(true);
    }
  };

  const handleShippingMethodContinue = () => {
    setCurrentStep(3);
  };

  const activeAddress = React.useMemo(
    () =>
      addressList?.find((item: any) => item.user_address_id === userAddressId),
    [addressList, userAddressId]
  );

  const showAddAddressForm = () => {
    setShowAddressForm(true);
    setUserAddressId(null);
  };

  const hideAddressForm = () => {
    setShowAddressForm(false);
  };

  const handleCouponApply = () => {
    if (couponCode && cartItemArray && cartItemArray.length > 0) {
      setIsLoading(true);
      setCouponError('');
      getCartPrice(
        cartItemArray,
        userAddressId,
        couponCode,
        shippingMethod?.shipping_key
      )
        .then((res) => {
          setIsLoading(false);
          if (!res.status) {
            setCouponError(res.message);
            return;
          }
          setCartPrice(res.data);
          setCouponCode('');
          setAppliedCouponCode(couponCode);
        })
        .catch((err) => {
          setIsLoading(false);
          setCouponError(err?.response?.data?.message || err.toString());
        });
    }
  };

  const fetchPriceByShippingMethod = (method: any) => {
    if (cartItemArray && cartItemArray.length > 0) {
      // setIsLoading(true);
      setSavingShippingMethod(true);
      setShippingMethod(method);
      getCartPrice(
        cartItemArray,
        userAddressId,
        appliedCouponCode,
        method?.shipping_key
      )
        .then((res) => {
          setSavingShippingMethod(false);
          // setIsLoading(false);
          if (!res.status) {
            return;
          }
          setCartPrice(res.data);
        })
        .catch(() => {
          setSavingShippingMethod(false);
          // setIsLoading(false);
        });
    }
  };

  const toggleShippingMethod = (method: any) => {
    if (method?.shipping_key === shippingMethod?.shipping_key) {
      fetchPriceByShippingMethod('');
    } else {
      fetchPriceByShippingMethod(method);
    }
  };

  const renderCouponForm = () => {
    return (
      <>
        <Form.Control
          placeholder="Enter coupon code"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          className="py-3"
        />
        <ErrorMessage>{couponError}</ErrorMessage>
        <div className="mt-3">
          <Button
            variant="dark"
            className="w-100 py-3"
            disabled={!couponCode}
            onClick={handleCouponApply}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" className="ms-2" />
            ) : (
              'Apply'
            )}
          </Button>
        </div>
      </>
    );
  };

  const handleAddressChange = (value: any) => {
    // const address = transformAddress(value);
    if (typeof value === 'string') {
      setValue('address_1', value);
      return;
    }
    const address = placesToAddress(value);
    const { state, city, address_1, address_2, postal_code } = address;
    // if (!city && !state) {
    //   setAddressState(addressString);
    //   return;
    // }
    setValue('address_1', address_1);
    setValue('address_2', address_2);
    setValue('city', city);
    setValue('state', state);
    setValue('postcode', postal_code);
    setValue('country', address.country);
    globalData.setSelectedCountry(address.country);
    globalData.setSelectedState(state);
    setAddressState(address_1);
  };
  const { errors } = formState;
  return (
    <>
      <Wrapper>
        <Content>
          <SiteHeader />
          <Container>
            <BreadCrumbView list={breadcrumbList} />
            <h4 className="mb-0 fw-700">Check out</h4>
            {priceLoader || cartLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <div className="checkout-main">
                <div className="d-flex checkout-wrapper">
                  <div className="d-none d-lg-block">
                    <div
                      className="select-bullet-main d-flex align-items-center"
                      style={{ position: 'relative' }}
                    >
                      <BulletIcon1 />
                      <Line
                        style={{
                          position: 'absolute',
                          height:
                            currentStep == 1
                              ? '50rem'
                              : currentStep == 3
                                ? '41rem'
                                : '29rem',
                        }}
                      />
                      <BulletIcon2
                        style={{
                          position: 'absolute',
                          top: currentStep == 1 ? '42.5rem' : '10.35rem',
                        }}
                      />
                      <BulletIcon3
                        style={{
                          position: 'absolute',
                          top:
                            currentStep == 1
                              ? '48.5rem'
                              : currentStep == 2
                                ? '26.75rem'
                                : '20.5rem',
                        }}
                      />
                    </div>
                    {/* <div className="select-bullet-main d-flex align-items-center">
                      <BulletIcon1 />
                      <Line
                        className={
                          currentStep == 1 ? 'select-line' : 'select-line2'
                        }
                      />
                      <BulletIcon2 />
                      <SelectLine
                        className={
                          currentStep == 1 ? 'select-line3' : 'select-line2'
                        }
                      />
                      <BulletIcon3 />
                    </div> */}
                  </div>

                  <div className="checkout-section-wrapper">
                    {/* Address details */}
                    <div className="form-main-section">
                      <div className="d-flex mb-2">
                        <div className="d-block d-lg-none select-bullet-main d-flex justify-content-between align-items-center">
                          <BulletIcon1 style={{ marginRight: '1rem' }} />
                        </div>
                        <div className="form-title fs-20 fw-600">Address</div>
                      </div>

                      {currentStep == 1 ? (
                        <div className="content-wrap">
                          {showAddressForm ? (
                            <Form
                              onSubmit={handleSubmit(onContinue)}
                              className="form"
                            >
                              <Container>
                                <Row>
                                  <Col sm={6}>
                                    <div className="form-label fs-1rem">
                                      Full name
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Enter full name"
                                      className="address-form-input"
                                      {...register('full_name')}
                                    />
                                    <ErrorMessage>
                                      {errors.full_name?.message}
                                    </ErrorMessage>
                                  </Col>
                                  <Col sm={6}>
                                    <div className="form-label fs-1rem">
                                      Phone number
                                    </div>
                                    <input
                                      placeholder="Enter phone number"
                                      className="address-form-input"
                                      {...register('phone_number')}
                                    />
                                    <ErrorMessage>
                                      {errors.phone_number?.message}
                                    </ErrorMessage>
                                  </Col>
                                </Row>
                                {user?.isGuest ? (
                                  <div>
                                    <div className="form-label fs-1rem">
                                      Email address
                                    </div>
                                    <input
                                      placeholder="Enter email"
                                      className="address-form-input"
                                      {...register('email')}
                                    />
                                    <ErrorMessage>
                                      {errors.email?.message}
                                    </ErrorMessage>
                                  </div>
                                ) : null}
                                <Row>
                                  <Col sm={6}>
                                    <div className="form-label fs-1rem">
                                      Address line 1
                                    </div>
                                    <AutoComplete
                                      name="address_1"
                                      value={addressState}
                                      // {...register('address_1')}
                                      onChange={handleAddressChange}
                                      inputClassName="address-form-input"
                                      placeholder="Enter address line 1"
                                    />
                                    <ErrorMessage>
                                      {errors.address_1?.message}
                                    </ErrorMessage>
                                  </Col>
                                  <Col sm={6}>
                                    <div className="form-label fs-1rem">
                                      Address line 2
                                    </div>
                                    {/* <AutoComplete
                                      name="address_2"
                                      onChange={(value) => {
                                        setValue('address_2', value);
                                      }}
                                      inputClassName="address-form-input"
                                      placeholder="Enter address line 2"
                                      value={getValues().address_2}
                                    /> */}
                                    <input
                                      placeholder="Enter address line 2"
                                      className="address-form-input"
                                      {...register('address_2')}
                                    />
                                    <ErrorMessage>
                                      {errors.address_2?.message}
                                    </ErrorMessage>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm={4}>
                                    <div className="form-label fs-1rem">
                                      Country
                                    </div>
                                    <div className="dropdown-container">
                                      <select
                                        value={
                                          globalData?.selectedCountry || ''
                                        }
                                        onChange={(e) =>
                                          globalData.setSelectedCountry(
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option>Select country</option>
                                        {globalData.countries &&
                                          globalData.countries.map(
                                            (item: any, index: number) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.isoCode}
                                                  className="address-form-input"
                                                >
                                                  {item.name}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                    </div>
                                    <ErrorMessage>
                                      {errors.countries?.message}
                                    </ErrorMessage>
                                  </Col>

                                  <Col sm={4}>
                                    <div className="form-label fs-1rem">
                                      State
                                    </div>
                                    <div className="dropdown-container">
                                      <select
                                        {...register('state')}
                                        value={globalData?.selectedState || ''}
                                        onChange={(e) =>
                                          globalData.setSelectedState(
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option>Select State</option>
                                        {globalData.states &&
                                          globalData.states.map(
                                            (item: any, index: number) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.isoCode}
                                                  className="address-form-input"
                                                >
                                                  {item.name}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                    </div>
                                    <ErrorMessage>
                                      {errors.state?.message}
                                    </ErrorMessage>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="form-label fs-1rem">
                                      City
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Enter city name"
                                      className="address-form-input"
                                      {...register('city')}
                                    />
                                    <ErrorMessage>
                                      {errors.city?.message}
                                    </ErrorMessage>
                                  </Col>
                                  <Col sm={4}>
                                    <div className="form-label fs-1rem">
                                      Postal code
                                    </div>
                                    <input
                                      type="text"
                                      placeholder="Enter postal code"
                                      className="address-form-input"
                                      {...register('postcode')}
                                    />
                                    <ErrorMessage>
                                      {errors.postcode?.message}
                                    </ErrorMessage>
                                  </Col>
                                </Row>
                                <div className="d-flex justify-content-end">
                                  {addressList.length !== 0 && (
                                    <StyledButton
                                      style={{
                                        marginTop: 32,
                                      }}
                                      variant="outline-dark mx-3"
                                      onClick={hideAddressForm}
                                    >
                                      Cancel
                                    </StyledButton>
                                  )}
                                  <StyledButton
                                    style={{
                                      marginTop: 32,
                                    }}
                                    variant="dark"
                                    type="submit"
                                  >
                                    {!savingAddress ? (
                                      'Continue'
                                    ) : (
                                      <Spinner animation="border" size="sm" />
                                    )}
                                  </StyledButton>
                                </div>
                              </Container>
                            </Form>
                          ) : (
                            <>
                              <div className="address-wrap">
                                {addressList?.map((address: any) => (
                                  <AddressCard
                                    key={address?.user_address_id}
                                    address={address}
                                    onSelect={() =>
                                      setUserAddressId(address?.user_address_id)
                                    }
                                    active={
                                      userAddressId === address?.user_address_id
                                    }
                                  />
                                ))}
                              </div>
                              <div className="address-actions">
                                <div className="d-flex justify-content-between m-2">
                                  <TransparentButton
                                    onClick={showAddAddressForm}
                                  >
                                    + Use another address{' '}
                                  </TransparentButton>
                                  <StyledButton
                                    variant="dark"
                                    onClick={handleAddressContinue}
                                  >
                                    Continue
                                  </StyledButton>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <AddressSummary
                          address={activeAddress ? activeAddress : form}
                          onBack={onEditAddress}
                        />
                      )}
                    </div>
                    {/** shipping method */}
                    <div className="form-main-section">
                      <div className="d-flex mb-2">
                        <div className="d-block d-lg-none select-bullet-main d-flex justify-content-between align-items-center">
                          <BulletIcon2 style={{ marginRight: '1rem' }} />
                        </div>
                        <div className="form-title fs-20 fw-600">
                          Shipping Method
                        </div>
                      </div>
                      {currentStep == 2 ? (
                        <div className="shippingFormContainer">
                          <div>
                            {shippingMethodList?.map(
                              (item: any, index: number) => (
                                <ShippingMethodCard
                                  key={index}
                                  shippingMethod={item}
                                  onSelect={toggleShippingMethod}
                                  active={
                                    shippingMethod?.shipping_key ===
                                    item?.shipping_key
                                  }
                                />
                              )
                            )}
                          </div>
                          <div className="d-flex justify-content-end mt-3">
                            <StyledButton
                              variant="dark"
                              onClick={handleShippingMethodContinue}
                            >
                              {!savingShippingMethod ? (
                                'Continue'
                              ) : (
                                <Spinner animation="border" size="sm" />
                              )}
                            </StyledButton>
                          </div>
                        </div>
                      ) : (
                        currentStep == 3 && (
                          <ShippingMethodSummary
                            method={shippingMethod}
                            onBack={() => setCurrentStep(2)}
                          />
                        )
                      )}
                    </div>
                    {/* Payment details */}
                    <div className="form-main-section">
                      <div className="d-flex mb-2">
                        <div className="d-block d-lg-none select-bullet-main d-flex justify-content-between align-items-center">
                          <BulletIcon3 style={{ marginRight: '1rem' }} />
                        </div>
                        <div className="form-title fs-20 fw-600">
                          Payment Details
                        </div>
                      </div>
                      {currentStep == 3 && (
                        <div className="checkoutFormContainer">
                          {/* {!showCardForm ? (
                            <div>
                              <SavedCards
                                cardList={cardList}
                                selectedCard={selectedCard}
                                onSelect={setSelectedCard}
                              />
                              <div className="d-flex justify-content-between m-2">
                                <TransparentButton onClick={showAddCardForm}>
                                  + Add another card
                                </TransparentButton>
                                <StyledButton
                                  variant="dark"
                                  disabled={
                                    selectedCard == null || processingPayment
                                  }
                                  onClick={() =>
                                    onPay(selectedCard?.stripe_card_id)
                                  }
                                >
                                  Pay
                                </StyledButton>
                              </div>
                            </div>
                          ) : (
                            <PaymentDetails
                              processingPayment={processingPayment}
                              onPay={onPay}
                              onCancel={onHideCardForm}
                            />
                          )} */}
                          {priceLoader ? (
                            <Loader height="200" />
                          ) : (
                            <PaymentWrapper>
                              <div>
                                <Image src="/images/paypal.png" width={135} />
                              </div>
                              <div className="d-sm-flex align-items-center g-1 fs-20 paymentPriceWrapper">
                                <h3>${cartPrice?.totalAmount}</h3>
                                <div className="flex-1">
                                  {!!cartPrice?.totalAmount && (
                                    <PaypalPay
                                      amount={cartPrice?.totalAmount}
                                      orderDetails={{
                                        user_product_ids: cartItemArray,
                                        user_address_id: userAddressId,
                                        couponCode: appliedCouponCode,
                                        shipping_method:
                                          shippingMethod?.shipping_key || '',
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </PaymentWrapper>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-none d-lg-block">
                    <OrderSummary cartPrice={cartPrice} />
                    <div>
                      <OrderDetailsWrapper>
                        <p>Do you have coupon code?</p>
                        {renderCouponForm()}
                      </OrderDetailsWrapper>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Order details at bottom for mobile view */}
            <div className="d-block d-lg-none">
              <OrderSummary cartPrice={cartPrice} />
              <div>
                <OrderDetailsWrapper>
                  <p>Do you have coupon code?</p>
                  <div className="d-flex gap-4">{renderCouponForm()}</div>
                </OrderDetailsWrapper>
              </div>
            </div>
          </Container>
        </Content>
      </Wrapper>
      <SiteFooter />
    </>
  );
}

export default BuyerCheckout;
